import { Palette } from '@typeform/ginger/dist/constants/palettes'
import React from 'react'
import { TActionBarProps } from 'components/molecules/action-bar'
import { ButtonVariant, TButtonProps } from 'components/atoms/button'
import { TModuleMediaContainerProps } from 'components/atoms/module-media-container'
import { TModuleContainerProps } from 'components/modules/module-container'

import { HeadlineModuleVariant } from '../headline-module'

export enum Variant {
  Default = 'Default',
  Compact = 'Compact',
  WithBackdrop = 'With backdrop',
  WithBackdropAndMedia = 'With backdrop and media',
}

export enum BackdropLayout {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

type TLink = Omit<TButtonProps, 'size'> & {
  palette?: Palette
}

export type TSecondLink = TLink & {
  type: ButtonVariant.Secondary | ButtonVariant.Tertiary
}

type TCommon = React.HTMLAttributes<HTMLDivElement> &
  TModuleContainerProps & {
    copyVariant?: HeadlineModuleVariant
    heading?: string
    subheading?: string
    onLinkClick?: TActionBarProps['onClick']
    backdropColor?: string
    backdropLayout?: BackdropLayout
    media?: TModuleMediaContainerProps['media']
    smallMedia?: TModuleMediaContainerProps['smallMedia']
    actionBar?: TActionBarProps | null
    palette?: Palette
  }

type TDefaultVariant = TCommon & {
  variant?: Variant.Default
}

type TCompactVariant = TCommon & {
  variant: Variant.Compact
}

type TWithBackgroundVariant = TCommon & {
  variant: Variant.WithBackdrop
  backdropColor?: string
  backdropLayout?: BackdropLayout
}

export type TWithBackgroundAndMediaVariant = TCommon & {
  variant: Variant.WithBackdropAndMedia
  backdropColor?: string
  media: TModuleMediaContainerProps['media']
}

export type TCtaModuleProps =
  | TDefaultVariant
  | TCompactVariant
  | TWithBackgroundVariant
  | TWithBackgroundAndMediaVariant
