import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { HeadlineModuleVariant } from '../headline-module'

import { BackdropLayout, Variant } from './types'

export const defaultProps = {
  variant: Variant.Default,
  copyVariant: HeadlineModuleVariant.Default,
  onLinkClick: () => {},
  backdropLayout: BackdropLayout.Vertical,
  backdropColor: NeutralColors.Grey100,
  media: null,
  smallMedia: null,
}
