import { BREAKPOINTS } from '@typeform/ginger/dist/constants/breakpoints'
import { onTrackItemClickEvent } from '@typeform/ginger/dist/util/tracking'
import React, { memo, useCallback } from 'react'
import ActionBar, {
  TActionBarMouseEventHandler,
} from 'components/molecules/action-bar'
import { trackEvent, trackExperimentEvent } from 'components/tracking'

import HeadlineModule, {
  HeadlineModuleAlignment,
  HeadlineModuleLayout,
} from '../headline-module'

import { TCtaModuleProps, BackdropLayout, Variant } from './types'
import { Backdrop, Headings, Links, Media, Section } from './cta-module.styles'
import { defaultProps } from './constants'

const CtaModule: React.FC<TCtaModuleProps> = ({
  copyVariant,
  heading,
  subheading,
  variant,
  onLinkClick,
  backdropLayout,
  backdropColor,
  media,
  smallMedia,
  actionBar,
  palette,
  children,
  ...rest
}) => {
  const hasBackdrop =
    variant === Variant.WithBackdrop || variant === Variant.WithBackdropAndMedia
  const hasMedia = variant === Variant.WithBackdropAndMedia && !!media
  const hasHorizontalBackdropLayout =
    variant === Variant.WithBackdrop &&
    backdropLayout === BackdropLayout.Horizontal
  const hasHeadings = Boolean(heading || subheading)

  const handleActionClick: TActionBarMouseEventHandler = useCallback(
    ({ link, event }) => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
      onLinkClick?.({ link, event })
    },
    [onLinkClick]
  )

  return (
    <Section
      data-qa='cta-module'
      variant={variant}
      copyVariant={copyVariant}
      {...rest}
    >
      <Backdrop hasBackdrop={hasBackdrop} backdropColor={backdropColor}>
        {hasHeadings && (
          <Headings
            alignLeft={hasHorizontalBackdropLayout || hasMedia}
            hasMedia={hasMedia}
          >
            <HeadlineModule
              isModuleChild
              title={heading || ''}
              titleProps={{ element: 'h2' }}
              description={subheading}
              variant={copyVariant}
              layout={
                variant === Variant.Compact
                  ? HeadlineModuleLayout.Compact
                  : HeadlineModuleLayout.Default
              }
              alignment={
                hasHorizontalBackdropLayout || hasMedia
                  ? HeadlineModuleAlignment.Leading
                  : HeadlineModuleAlignment.Default
              }
            />
          </Headings>
        )}
        <Links
          alignLeft={hasMedia}
          alignRight={hasHorizontalBackdropLayout}
          hasHeadings={hasHeadings}
        >
          <ActionBar
            {...actionBar}
            palette={palette}
            onClick={handleActionClick}
          />
        </Links>
        {hasMedia && (
          <Media
            media={media}
            smallMedia={smallMedia}
            thresholdBreakpoint={BREAKPOINTS.md}
            hasHeadings={hasHeadings}
          />
        )}
      </Backdrop>
      {children}
    </Section>
  )
}

CtaModule.defaultProps = defaultProps

export default memo(CtaModule)
