import { NAMESPACES } from 'constants/locales'

import React, { memo } from 'react'
import CtaModule, {
  BackdropLayout,
  Variant,
} from 'components/modules/cta-module'
import OptimizedImage from 'components/optimized-media/optimized-image'
import { getStaticAsset } from 'utils/assets'
import useTranslation from 'next-translate/useTranslation'
import { trackExperimentEvent } from 'components/tracking'

import TrackingLocation, {
  TrackingLocationNames,
} from '../common/tracking-location'
import { HOMEPAGE_REFRESH_TEST_ID } from '../constants'

const CtaSection = () => {
  const { t } = useTranslation(NAMESPACES.HOME)
  const handleClick = () => {
    trackExperimentEvent({
      testId: HOMEPAGE_REFRESH_TEST_ID,
      name: 'homepage_refresh_read_on_click',
    })
  }

  return (
    <TrackingLocation name={TrackingLocationNames.CtaSection}>
      <CtaModule
        variant={Variant.WithBackdropAndMedia}
        backdropLayout={BackdropLayout.Vertical}
        backdropColor='#FFEEEA'
        heading={t('homepage.refresh.cta-section.title')}
        subheading={t('homepage.refresh.cta-section.description')}
        actionBar={{
          primaryLink: {
            label: t('homepage.refresh.cta-section.cta.label'),
            href: t('homepage.refresh.cta-section.cta.link'),
            onClick: handleClick,
          },
        }}
        media={
          <OptimizedImage
            src={getStaticAsset('homepage-refresh/cta-module/media.webp')}
            srcSet={`${getStaticAsset(
              'homepage-refresh/cta-module/media.webp'
            )}, ${getStaticAsset(
              'homepage-refresh/cta-module/media@2x.webp'
            )} 2x`}
          />
        }
      />
    </TrackingLocation>
  )
}

CtaSection.displayName = 'CtaSection'

export default memo(CtaSection)
