import { spacing } from '@typeform/ginger/dist/util/spacing'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'
import Grid from 'components/atoms/grid-container/next'
import ModuleMediaContainer from 'components/atoms/module-media-container'
import ModuleContainer from 'components/modules/module-container'

import { TCtaModuleProps, Variant } from './types'

export const Backdrop = styled(Grid)<{
  backdropColor: TCtaModuleProps['backdropColor']
  hasBackdrop: boolean
}>`
  row-gap: 0;

  ${({ hasBackdrop, backdropColor }) =>
    hasBackdrop &&
    css`
      background-color: ${backdropColor};
      border-radius: 64px;

      ${mediaQueries.md`
        border-radius: 72px;
      `}

      ${mediaQueries.lg`
        border-radius: 80px;
      `}

      ${mediaQueries.xl`
        border-radius: 96px;
      `}
    `}
`

export const Section = styled(ModuleContainer)<
  Pick<TCtaModuleProps, 'variant' | 'copyVariant'>
>`
  ${({ variant }) => {
    switch (variant) {
      case Variant.Compact:
        return css`
          padding-bottom: ${spacing(8)};
        `
      case Variant.WithBackdrop:
      case Variant.WithBackdropAndMedia:
        return css`
          padding: ${spacing(3, 2)};

          ${Backdrop} {
            padding: ${spacing(8, 2)};
          }
        `
      default:
        return css`
          padding-top: ${spacing(8)};
          padding-bottom: ${spacing(8)};

          ${mediaQueries.sm`
            padding-top: ${spacing(10)};
            padding-bottom: ${spacing(10)};
          `}

          ${mediaQueries.md`
            padding-top: ${spacing(12)};
            padding-bottom: ${spacing(12)};
          `}
        `
    }
  }};
`

export const Headings = styled.div<{
  alignLeft: boolean
  hasMedia: boolean
}>`
  padding: 0;
  text-align: center;
  grid-column: 1 / -1;
  margin-bottom: ${spacing(5)};

  ${mediaQueries.md`
    ${({ alignLeft, hasMedia }: { alignLeft: boolean; hasMedia: boolean }) =>
      alignLeft &&
      css`
        grid-column: 2 / 8;
        text-align: left;
        margin-bottom: ${hasMedia ? spacing(5) : 0};
        align-self: self-end;
      `}
  `}

  ${mediaQueries.xl`
    ${({ alignLeft }: { alignLeft: boolean }) =>
      alignLeft &&
      css`
        grid-column: 2 / 8;
        text-align: left;
      `}
  `}
`

type TLinks = {
  alignLeft: boolean
  alignRight: boolean
  hasHeadings: boolean
}

export const Links = styled.div<TLinks>`
  grid-column: 1 / -1;
  grid-row: ${({ hasHeadings }) => (hasHeadings ? 'unset' : 2)};

  ${mediaQueries.sm`
    justify-self: center;
  `}

  ${({ alignLeft, alignRight }) => css`
    ${mediaQueries.md`
      ${
        alignRight &&
        css`
          grid-column: -6 / -2;
          align-self: center;
          justify-self: center;
        `
      }

      ${
        alignLeft &&
        css<Pick<TLinks, 'hasHeadings'>>`
          grid-column: 2 / 8;
          justify-self: left;

          ${({ hasHeadings }) =>
            !hasHeadings &&
            css`
              grid-row: 1 / 3;
              align-self: center;
            `}
        `
      }
    `}
  `}
`

export const Media = styled(ModuleMediaContainer)<{
  hasHeadings: boolean
}>`
  grid-column: 1 / -1;
  grid-row: ${({ hasHeadings }) => (hasHeadings ? 2 : 'unset')};
  margin: ${spacing(0, -2, 5, -2)};
  width: auto;

  ${mediaQueries.sm`
    grid-column: 3 / -3;
    margin: ${spacing(0, -3, 5, -3)}
  `}

  ${mediaQueries.md`
    grid-column: 8 / -2;
    grid-row: 1 / 3;
    margin: ${spacing(-8, 0)};
  `}

  img {
    width: 100%;
    object-fit: cover;

    ${mediaQueries.md`
      height: 100%;
    `}
  }

  .lf-player-container {
    line-height: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
